import React from 'react';
import { Link } from 'gatsby';

import Page from '../templates/Page';
import { SEO, Intro, Section, Heading, Article, ArticleColumn, TextAccent } from '../components';

const HeyPolls = () => (
  <Page
    preContent={
      <Intro pageTitle="Hey, Polls!" colorScheme="light" style={{ background: '#50c0ff' }}>
        <h1>Hi from the second page</h1>
        <p>Welcome to page 2</p>
        <Link to="/">Go back to the homepage</Link>
      </Intro>
    }
  >
    <SEO title="Hey, Polls!" />
    <Section title="Hey, Polls!">
      <Article>
        <TextAccent>
          I served as the head of the Product Design team for Trunk Club, leading a talented group of designers
          rethinking fashion retail. We positioned Design as a strategic partner to the business, providing research,
          software design, to in-person customer experience design. I enjoyed defining career paths, setting
          industry-standard salaries, and encouraging our team to practice writing. In addition to leading the Product
          Design team, I directed the 2017 redesign of Trunk Club’s marketing site, focusing on the at-home trunk
          business while reshaping a more approachable brand.
        </TextAccent>
      </Article>
      <Article variation="1/3">
        <ArticleColumn>
          <Heading element="h2" weight={500}>
            Polls created:
          </Heading>
          <TextAccent element="h1">↗ 12k</TextAccent>
        </ArticleColumn>
        <ArticleColumn>
          <Heading element="h2" weight={500}>
            Some reasoning around things
          </Heading>
          <TextAccent>
            Across product design, branding, marketing, social media, and in-store experiences, our work is created to
            enhance the iconic Nike brand and ensure it connects with users at every touchpoint.
          </TextAccent>
        </ArticleColumn>
      </Article>
      <Article>
        <TextAccent>
          I served as the head of the Product Design team for Trunk Club, leading a talented group of designers
          rethinking fashion retail. We positioned Design as a strategic partner to the business, providing research,
          software design, to in-person customer experience design. I enjoyed defining career paths, setting
          industry-standard salaries, and encouraging our team to practice writing. In addition to leading the Product
          Design team, I directed the 2017 redesign of Trunk Club’s marketing site, focusing on the at-home trunk
          business while reshaping a more approachable brand.
        </TextAccent>
      </Article>
      <Article variation="1/2">
        <ArticleColumn>
          <Heading element="h2" weight={500}>
            Polls created:
          </Heading>
          <TextAccent element="h1">↗ 12k</TextAccent>
        </ArticleColumn>
        <ArticleColumn>
          <Heading element="h2" weight={500}>
            Some reasoning around things
          </Heading>
          <TextAccent>
            Across product design, branding, marketing, social media, and in-store experiences, our work is created to
            enhance the iconic Nike brand and ensure it connects with users at every touchpoint.
          </TextAccent>
        </ArticleColumn>
      </Article>
      <Article variation="b">
        <Heading element="h4" weight={500}>
          Statistic detail
        </Heading>
        <TextAccent>
          Across product design, branding, marketing, social media, and in-store experiences, our work is created to
          enhance the iconic Nike brand and ensure it connects with users at every touchpoint.
        </TextAccent>
      </Article>
      <Article>
        <TextAccent withSpacing>
          I served as the head of the Product Design team for Trunk Club, leading a talented group of designers
          rethinking fashion retail. We positioned Design as a strategic partner to the business, providing research,
          software design, to in-person customer experience design. I enjoyed defining career paths, setting
          industry-standard salaries, and encouraging our team to practice writing. In addition to leading the Product
          Design team, I directed the 2017 redesign of Trunk Club’s marketing site, focusing on the at-home trunk
          business while reshaping a more approachable brand.
        </TextAccent>
        <TextAccent>
          I served as the head of the Product Design team for Trunk Club, leading a talented group of designers
          rethinking fashion retail. We positioned Design as a strategic partner to the business, providing research,
          software design, to in-person customer experience design. I enjoyed defining career paths, setting
          industry-standard salaries, and encouraging our team to practice writing. In addition to leading the Product
          Design team, I directed the 2017 redesign of Trunk Club’s marketing site, focusing on the at-home trunk
          business while reshaping a more approachable brand.
        </TextAccent>
      </Article>
      <Article variation="bt">
        <Heading element="h4" weight={500}>
          Statistic detail
        </Heading>
        <TextAccent>
          Across product design, branding, marketing, social media, and in-store experiences, our work is created to
          enhance the iconic Nike brand and ensure it connects with users at every touchpoint.
        </TextAccent>
      </Article>
      <Article variation="1/3">
        <ArticleColumn>
          <Heading element="h2" weight={500}>
            Polls created:
          </Heading>
          <TextAccent element="h1">↗ 12k</TextAccent>
        </ArticleColumn>
        <ArticleColumn>
          <Heading element="h2" weight={500}>
            Some reasoning around things
          </Heading>
          <TextAccent>
            Across product design, branding, marketing, social media, and in-store experiences, our work is created to
            enhance the iconic Nike brand and ensure it connects with users at every touchpoint.
          </TextAccent>
        </ArticleColumn>
      </Article>
      <Article>
        <TextAccent>
          I served as the head of the Product Design team for Trunk Club, leading a talented group of designers
          rethinking fashion retail. We positioned Design as a strategic partner to the business, providing research,
          software design, to in-person customer experience design. I enjoyed defining career paths, setting
          industry-standard salaries, and encouraging our team to practice writing. In addition to leading the Product
          Design team, I directed the 2017 redesign of Trunk Club’s marketing site, focusing on the at-home trunk
          business while reshaping a more approachable brand.
        </TextAccent>
      </Article>
    </Section>
  </Page>
);

export default HeyPolls;
